import React from 'react'
import AboutUsImg from "../asset/img/about-us.png"

const AboutUs = () => {
    return (
        <div className='about-us-content py-5' id='about-us'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <h3 className='info-card-title'>Hakkımızda</h3>
                        <p className='info-card-paragraph mb-0'>Biz, finansal okuryazarlık eğitimine adanmış bir ekibiz. Amacımız, bireylerin mali bilgilerini artırarak, daha bilinçli ve güvenli finansal kararlar almalarını sağlamak. Profesyonel eğitimlerimiz ve rehberlik hizmetlerimizle her zaman yanınızdayız. Finansal geleceğinizi güvence altına almak için bize katılın!</p>
                    </div>
                    <div className="col-lg-6 text-end">
                        <img src={AboutUsImg} alt="about-us" width="70%" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs