import React, { useState } from 'react'
import ContactUs from "../asset/img/contact-us-6114172-5059493.png"
import axios from 'axios';
import Swal from 'sweetalert2';

const Contact = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { firstName, lastName, email, phone } = formData;
        const data = {
            firstName,
            lastName,
            email,
            phoneNumber: phone
        };
        // https://ParaninSirlari.com
        try {
            await axios.post(`${window.location.origin}/api/Form/SubmitForm`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            Swal.fire({
                title: "Başarılı",
                text: "Mesajınız başarıyla iletilmiştir",
                icon: "success"
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            })
        } catch (error) {
            console.error('Form submission error:', error);
            Swal.fire({
                title: "Bir Hata Oluştu",
                text: "Bir hata oluştu. Lütfen tekrar deneyiniz.",
                icon: "error"
            });
        }
    };
    return (
        <div className='contact-content' id='contact'>
            <div className="container contact-content-container px-lg-5 py-lg-5 px-3 py-5">
                <div className="row align-items-center contact-mobile">
                    <div className="col-lg-6">
                        <form onSubmit={handleSubmit}>
                            <div className='d-flex flex-column'>
                                <label htmlFor="firstName" className='form-label'>İsim</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    placeholder='İsminizi Girin'
                                    className='form-control'
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className='d-flex flex-column mt-3'>
                                <label htmlFor="lastName" className='form-label'>Soyisim</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    placeholder='Soyisminizi Girin'
                                    className='form-control'
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className='d-flex flex-column mt-3'>
                                <label htmlFor="email" className='form-label'>E-posta Adresi</label>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder='E-posta Adresinizi Girin'
                                    className='form-control'
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className='d-flex flex-column mt-3'>
                                <label htmlFor="phone" className='form-label'>Telefon Numarası</label>
                                <input
                                    type="tel"
                                    name="phone"
                                    placeholder='Telefon Numaranızı Girin'
                                    className='form-control'
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className='d-flex flex-column mt-3'>
                                <label htmlFor="phone" className='form-label'>Referans Kodu</label>
                                <input
                                    type="text"
                                    name="reference"
                                    placeholder='Referans Kodunuzu Girin'
                                    className='form-control'
                                />
                            </div>
                            <div className='mt-4'>
                                <button className='btn btn-light py-2 w-100' type="submit">
                                    Gönder
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-6">
                        <div className="mb-5">
                            <h3 className='text-center contact-title mb-3'>İletişim Formu</h3>
                            <p className='text-center contact-paragraph'>Yandaki formu doldurarak bize ulaşabilirsiniz. Ekibimiz en kısa sürede sizinle iletişime geçecektir.</p>
                        </div>
                        <div className="text-center">
                            <img src={ContactUs} alt="contact" width="50%" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact