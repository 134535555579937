import React from 'react'
import WhyUs from '../asset/img/why-us.svg'

const SingleInfo = () => {
    return (
        <div className='single-info-content' id='single-info'>
            <div className="container">
                <h3 className='single-info-title'>Geleceğinizi Birlikte Planlayalım, Mali Başarıya Ulaşın!</h3>
                <div className="row align-items-center justify-content-center" style={{ marginTop: "100px" }}>
                    <div className="col-lg-6">
                        <img src={WhyUs} alt="single-info-image" width="100%" className='info-image' />
                    </div>
                    <div className="col-lg-5 pt-lg-5">
                        <h3 className='info-card-title'>Neden Bizi Tercih Etmelisiniz?</h3>
                        <p className='info-card-paragraph'>Uzman eğitmenlerimiz ve kişiye özel yaklaşımımızla finansal okuryazarlığınızı artırın. Etkili eğitim programlarımız ile mali hedeflerinize daha hızlı ulaşın. Güvenilir ve pratik bilgilerle donatılmış eğitimlerimiz sayesinde paranızı en iyi şekilde yönetin.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleInfo