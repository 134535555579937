import React from 'react'

const Footer = () => {
    return (
        <div className='footer-content py-4'>
            <div className="container">
                <p className='mb-0'>Paranın Sırları - Tüm Hakları Saklıdır - 2024</p>
            </div>
        </div>
    )
}

export default Footer