import React from 'react'
import Management from "../asset/img/management.webp"
import Investing from "../asset/img/investing.jpg"
import Debt from "../asset/img/debt.jpg"
import Retired from "../asset/img/hero-retirement-calculations-update-noaws-1140x570.avif"

const Info = () => {
    return (
        <div className='info-content' id='info'>
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-5">
                        <h3 className='info-card-title'>Bütçe Yönetimi Eğitimi</h3>
                        <p className='info-card-paragraph'>Gelir ve giderlerinizi etkili bir şekilde yönetmeyi öğrenin. Size özel bütçe planlaması ile mali hedeflerinize daha hızlı ulaşın.</p>
                    </div>
                    <div className="col-lg-6">
                        <img src={Management} alt="info-image" width="100%" className='info-image' />
                    </div>
                </div>
                <div className="row align-items-center justify-content-center info-card-content">
                    <div className="col-lg-6">
                        <img src={Investing} alt="info-image" width="90%" className='info-image' />
                    </div>
                    <div className="col-lg-5 text-end">
                        <h3 className='info-card-title'>Yatırım Stratejileri</h3>
                        <p className='info-card-paragraph'>Riskleri minimize ederek, karlı yatırım stratejileri geliştirin. Hisse senetleri, tahviller ve diğer yatırım araçları hakkında bilgi edinin.</p>
                    </div>
                </div>
                <div className="row align-items-center justify-content-center info-card-content">
                    <div className="col-lg-5">
                        <h3 className='info-card-title'>Borç Yönetimi</h3>
                        <p className='info-card-paragraph'>Borçlarınızı nasıl daha hızlı ve etkili bir şekilde ödeyebileceğinizi öğrenin. Faiz oranlarını düşürme ve kredi notunuzu yükseltme yollarını keşfedin.</p>
                    </div>
                    <div className="col-lg-5">
                        <img src={Debt} alt="info-image" width="100%" className='info-image' />
                    </div>
                </div>
                <div className="row align-items-center justify-content-center info-card-content">
                    <div className="col-lg-6">
                        <img src={Retired} alt="info-image" width="100%" className='info-image' />
                    </div>
                    <div className="col-lg-5 text-end">
                        <h3 className='info-card-title'>Emeklilik Planlaması</h3>
                        <p className='info-card-paragraph'>Rahat bir emeklilik için bugünden plan yapın. Emeklilik fonları ve birikim yöntemleri hakkında kapsamlı bilgi edinin.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Info