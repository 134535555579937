import React from 'react'
import BannerImg from "../asset/img/banner-img.png"

const Banner = () => {
    return (
        <div className='banner-content' id='banner'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="row align-items-center">
                            <div className="col-lg-6 py-lg-0 py-4">
                                <h3 className='info-card-title'>Finansal Okuryazarlığınızı Artırın</h3>
                                <p className='info-card-paragraph'>Geleceğinizi güvence altına alın! Finansal okuryazarlık eğitimi ile paranızı daha iyi yönetin, birikimlerinizi artırın ve yatırımlarınızı akıllıca yapın.</p>
                            </div>
                            <div className="col-lg-6">
                                <img src={BannerImg} alt="banner" width="100%" />
                            </div>
                        </div>
                        <div className="text-center">
                            <a href="#contact" style={{color: "#359CAA", textDecoration: "none"}}>
                                <div style={{ fontWeight: 500, fontSize: 15 }}>Bizimle iletişime geçmek için tıklayın
                                </div>
                                <div className='mt-1'><i className="fa-solid fa-chevron-down"></i></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner